
// プログレスバーの長さを調整
document.addEventListener('DOMContentLoaded', () => {
  const progress = document.querySelector('.l-header__progress');
  if (!progress) return;
  const firstCircle = progress.querySelector('.l-header__nav:first-child .l-header__circle');
  const lastCircle = progress.querySelector('.l-header__nav:last-child .l-header__circle');

  const calculateWidth = () => {
    const progressRect = progress.getBoundingClientRect();
    const firstCircleRect = firstCircle.getBoundingClientRect();
    const lastCircleRect = lastCircle.getBoundingClientRect();

    const startX = firstCircleRect.right - progressRect.left - 10;
    const endX = lastCircleRect.left - progressRect.left + 10;
    const width = endX - startX;

    progress.style.setProperty('--progress-width', `${width}px`);
  };

  window.addEventListener('resize', calculateWidth);
  window.addEventListener('load', calculateWidth);
  calculateWidth();
});

/**
 * オリジナルで作る(別注)：仕様の入力
 * - エンターでのsubmit防止
 * - バリデーション
 * - フォームの選択による数値の自動制御
 */

document.addEventListener('DOMContentLoaded', () => {
  const originalForm = document.getElementById('js-originalNewForm');
  const formSubmit = document.getElementById('js-formSubmit');
  if (!originalForm) {
    return;
  }
  /**
   * フォームのエンターキーと送信時のバリデーション表示
   */
  // Enter押下時に送信させない
  originalForm.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      const target = event.target;
      if (target.tagName === 'INPUT' || target.tagName === 'SELECT' || target.tagName === 'TEXTAREA') {
        event.preventDefault();
        const validity = target.validity;
        if (!validity.valid) {
          target.reportValidity();
        }
      }
    }
  });
  // 送信時のバリデーション
  formSubmit?.addEventListener('click', (event) => {
    const fields = Array.from(originalForm.querySelectorAll('input[type="number"], input[type="text"][required], select.js-selectRequired'));

    let isValid = true;
    let firstInvalidElement = null;

    fields.forEach((field) => {
      const errorMessage = field.closest('.p-original__row').querySelector('.c-form__error');
      if (field.tagName === 'SELECT' && field.value === '') {
        isValid = false;
        errorMessage.textContent = '選択してください';
        errorMessage.style.display = 'block';
        if (!firstInvalidElement) {
          firstInvalidElement = field;
        }
      } else if (!field.checkValidity()) {
        isValid = false;
        errorMessage.textContent = field.validationMessage;
        errorMessage.style.display = 'block';
        if (!firstInvalidElement) {
          firstInvalidElement = field;
        }
      } else {
        errorMessage.textContent = '';
        errorMessage.style.display = 'none';
      }
    });

    if (!isValid) {
      event.preventDefault();
      firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const area = document.getElementById('order_original_lot_delivery_area').value;
    const address = document.getElementById('order_original_delivery_address').value;

    if (!address.includes(area)) {
      event.preventDefault();
      alert('配送先地域と配送先住所が一致していません。');
    }

    // 沖縄県の場合は送料が着払いであることを確認
    if (area === '沖縄県') {
      event.preventDefault();  // フォーム送信を一時的に停止
      if (confirm('沖縄は別途、配送料のみ着払いでの対応となります。よろしいですか？')) {
        // OKの場合、フォームを送信
        originalForm.submit();
      }
      // キャンセルの場合は何もせず、フォーム送信を中止
    }
  });


  // --------------------------------------------------------------------------


  // 印刷の有無
  const isPrintRadios = document.querySelectorAll('input[name="order_original[is_print]"]');
  // フタのタイプのラジオボタンの親要素
  // styleを変更するケースを考慮し、disabledクラスを付与する
  const lidType = document.getElementById('js-lidType');
  // フタのタイプ
  const lidTypeRadios = document.querySelectorAll('input[name="order_original[lid_type]"]');
  // フタのタイプ浅フタ
  const lidTypeShallowRadio = document.querySelector('input[name="order_original[lid_type]"][value="shallow"]');
  // フタのタイプ深フタ
  const lidTypeDeepRadio = document.querySelector('input[name="order_original[lid_type]"][value="deep"]');
  // 縦のフィールド
  const verticalField = document.getElementById('order_original_vertical');
  verticalField.value = verticalField.min; // 初期値をminに設定
  // 横のフィールド 
  const horizontalField = document.getElementById('order_original_horizontal');
  horizontalField.value = horizontalField.min; // 初期値をminに設定
  // 身箱(ソコ)の高さのフィールド
  const heightField = document.getElementById('order_original_height');
  heightField.value = heightField.min; // 初期値をminに設定
  // フタの高さのフィールド
  const lidHeightField = document.getElementById('order_original_lid_height');
  lidHeightField.value = lidHeightField.min; // 初期値をminに設定
  const boxLidColorId = document.getElementById('order_original_box_lid_color_id');
  const boxLidColorOptions = boxLidColorId.querySelectorAll('option');
  const colorListBox = document.getElementById('color-list');
  /**
   * 印刷の有無によってフタのタイプを自動制御する
   * 印刷ありの場合、自動で浅フタとなります。の仕様に対応
   */
  const updateLidType = () => {
    if (isPrintRadios[0].checked) {
      lidTypeShallowRadio.checked = true;
      lidTypeDeepRadio.disabled = true;
      lidType.classList.add('disabled');
      // boxLidColorIdについて、optionのテキストがタント_N-8のものを選択、さらにフィールドのcssをpointer-events:noneにする
      boxLidColorOptions.forEach((option) => {
        if (option.textContent === 'タント_N-8') {
          option.selected = true;
          boxLidColorId.classList.add('is-disabled');
          colorListBox.classList.add('is-disabled');
          boxLidColorId.style.pointerEvents = 'none';
        }
      });
    } else {
      lidTypeDeepRadio.disabled = false;
      lidType.classList.remove('disabled');
      colorListBox.classList.remove('is-disabled');
      boxLidColorId.classList.remove('is-disabled');
      boxLidColorId.style.pointerEvents = 'auto';
    }
  }

  /**
   * 色選択の更新を行う関数
   * @param {string} selectId - セレクトボックスのID
   * @param {string} listId - 色リストのID
   * @param {string} itemAttribute - リストアイテムの属性名
   */
  const updateColorSelection = (selectId, listId, itemAttribute) => {
    const colorSelect = document.getElementById(selectId);
    const colorList = document.getElementById(listId);
    const colorItems = colorList.querySelectorAll('.p-list__item');

    // リストアイテムクリック時の処理
    colorItems.forEach(item => {
      item.addEventListener('click', () => {
        const colorName = item.querySelector('.p-list__text').textContent;

        // セレクトボックスのオプションを探す
        const option = Array.from(colorSelect.options).find(opt => opt.text === colorName);
        console.log(Array.from(colorSelect.options));
        if (option) {
          // オプションが見つかった場合、それを選択する
          colorSelect.value = option.value;
          // is-activeクラスの更新
          colorItems.forEach(ci => ci.classList.remove('is-active'));
          item.classList.add('is-active');

          // changeイベントをディスパッチ
          const event = new Event('change', { bubbles: true });
          colorSelect.dispatchEvent(event);
        }
      });
    });

    // セレクトボックスの変更時の処理
    colorSelect.addEventListener('change', () => {
      const selectedColorName = colorSelect.options[colorSelect.selectedIndex].text;

      colorItems.forEach(item => {
        const itemColorName = item.getAttribute(itemAttribute);
        if (itemColorName === selectedColorName) {
          item.classList.add('is-active');
        } else {
          item.classList.remove('is-active');
        }
      });
    });
  };

  /**
   * フィールドの制限値をラジオボタンの選択肢によって自動制御する
   * ツールチップに表示される仕様に対応。仕様変更時に要変更
   */
  // 各フィールドの制限値をオブジェクトとして定義
  const fieldLimits = {
    // 上から
    // 印刷ありの場合
    // 印刷なし・浅フタ
    // 印刷なし・深フタ
    print: {
      vertical: { min: 75, max: 400 },
      horizontal: { min: 50, max: 240 },
      height: { min: 30, max: 85 },
      lidHeight: { min: 15, max: 25 }
    },
    shallow: {
      vertical: { min: 75, max: 400 },
      horizontal: { min: 50, max: 320 },
      height: { min: 30, max: 85 },
      lidHeight: { min: 15, max: 25 }
    },
    deep: {
      vertical: { min: 75, max: 400 },
      horizontal: { min: 50, max: 320 },
      height: { min: 15, max: 85 },
      lidHeight: { min: 0, max: 85 }
    }
  };

  const updateFieldLimits = () => {
    let limits;
    if (isPrintRadios[0].checked) {
      limits = fieldLimits.print;
    } else if (lidTypeShallowRadio.checked) {
      limits = fieldLimits.shallow;
    } else {
      limits = fieldLimits.deep;
    }

    verticalField.min = limits.vertical.min;
    verticalField.max = limits.vertical.max;
    if (verticalField.value < limits.vertical.min) {
      verticalField.value = limits.vertical.min;
      drawBox();
    } else if (verticalField.value > limits.vertical.max) {
      verticalField.value = limits.vertical.max;
      drawBox();
    }
    horizontalField.min = limits.horizontal.min;
    horizontalField.max = limits.horizontal.max;
    if (horizontalField.value < limits.horizontal.min) {
      horizontalField.value = limits.horizontal.min;
      drawBox();
    } else if (horizontalField.value > limits.horizontal.max) {
      horizontalField.value = limits.horizontal.max;
      drawBox();
    }
    heightField.min = limits.height.min;
    heightField.max = limits.height.max;
    if (heightField.value < limits.height.min) {
      heightField.value = limits.height.min;
      drawBox();
    } else if (heightField.value > limits.height.max) {
      heightField.value = limits.height.max;
      drawBox();
    }
    lidHeightField.min = limits.lidHeight.min;
    lidHeightField.max = limits.lidHeight.max;
    if (lidHeightField.value < limits.lidHeight.min) {
      lidHeightField.value = limits.lidHeight.min;
      drawBox();
    } else if (lidHeightField.value > limits.lidHeight.max) {
      lidHeightField.value = limits.lidHeight.max;
      drawBox();
    }
  };

  const updateNumberFields = () => {
    const numberFields = document.querySelectorAll('input[type="number"]');

    numberFields.forEach(field => {
      const sessionValue = field.dataset.sessionValue;
      if (sessionValue !== '' && sessionValue !== undefined && sessionValue !== '0' && sessionValue !== null) {
        field.value = sessionValue;
      }
    });
  }


  /**
   * 印刷なし/深フタの時、フタの高さは身箱(ソコ)の高さから3mm引いた高さにする
   */
  const updateLidHeight = () => {
    if (lidTypeDeepRadio.checked && !isPrintRadios[0].checked) {
      const lidEngagement = heightField.value <= 30 ? 4 : heightField.value <= 59 ? 5 : 6;
      lidHeightField.step = 0.5;
      lidHeightField.value = heightField.value >= 15 ? heightField.value - lidEngagement / 2 : 0;
      lidHeightField.classList.add('is-disabled');
    } else {
      lidHeightField.step = 5;
      lidHeightField.classList.remove('is-disabled');
    }
  }

  isPrintRadios.forEach((radio) => {
    radio.addEventListener('change', () => {
      updateLidType();
      updateFieldLimits();
      updateLidHeight();
    });
  });
  lidTypeRadios.forEach((radio) => {
    radio.addEventListener('change', () => {
      updateFieldLimits();
      updateLidHeight();
    });
  });
  heightField.addEventListener('change', () => {
    updateLidHeight();
  })

  window.addEventListener("load", () => {
    updateNumberFields();
    updateLidType();
    updateFieldLimits();
    updateLidHeight();
    // 蓋の色の選択
    updateColorSelection('order_original_box_lid_color_id', 'color-list', 'color-category');
    // 身箱の色の選択
    updateColorSelection('order_original_box_body_color_id', 'boxColor-list', 'box-color-category');
  });


  // ------------------------------------------------------------------------------------

  /**
   * 別注フォームの自動見積もり機能
   * displayResult: 見積もり結果がまだ出ない段階での表示を制御する
   * calculateEstimate: 見積もりを実行する
   */

  function displayResult(elementId, value, errorMessage) {
    const element = document.getElementById(elementId);
    if (!isNaN(value)) {
      element.textContent = value.toLocaleString();
    } else {
      element.textContent = errorMessage;
    }
  }


  const optionCheckboxes = document.querySelectorAll('.option-check');

  // function updateOptionsState() {
  //   let isFoiled = false;
  //   let isInsideIsBlack = false;
  //   let foiledSilver = false;
  //   let foiledGold = false;

  //   optionCheckboxes.forEach(checkbox => {
  //     if (checkbox.checked) {
  //       const optionName = checkbox.dataset.name;

  //       if (optionName.includes('foiled')) {
  //         isFoiled = true;
  //         if (optionName === 'foiled_silver') foiledSilver = true;
  //         if (optionName === 'foiled_gold') foiledGold = true;
  //       }

  //       if (optionName === 'black') {
  //         isInsideIsBlack = true;
  //       }
  //     }
  //   });

  //   // foiled_silver と foiled_gold の排他制御
  //   if (foiledSilver) {
  //     optionCheckboxes.forEach(cb => {
  //       if (cb.dataset.name === 'foiled_gold') cb.disabled = true;
  //     });
  //   } else if (foiledGold) {
  //     optionCheckboxes.forEach(cb => {
  //       if (cb.dataset.name === 'foiled_silver') cb.disabled = true;
  //     });
  //   } else {
  //     optionCheckboxes.forEach(cb => {
  //       if (cb.dataset.name === 'foiled_silver' || cb.dataset.name === 'foiled_gold') {
  //         cb.disabled = false;
  //       }
  //     });
  //   }

  //   console.log('Is foiled:', isFoiled);
  //   console.log('Is inside black:', isInsideIsBlack);

  //   // ここで isFoiled と isInsideIsBlack を使用して金額計算などを行う
  //   // 例: calculatePrice(isFoiled, isInsideIsBlack);
  // }

  // optionCheckboxes.forEach(checkbox => {
  //   checkbox.addEventListener('change', updateOptionsState);
  // });

  // // 初期状態の設定
  // updateOptionsState();


  function calculateEstimate() {
    // 入力値の取得
    const vertical = parseInt(document.getElementById('order_original_vertical').value);
    const horizontal = parseInt(document.getElementById('order_original_horizontal').value);
    const height = parseInt(document.getElementById('order_original_height').value);
    const formLidHeight = parseInt(document.getElementById('order_original_lid_height').value);
    const lidType = document.querySelector('input[name="order_original[lid_type]"]:checked').value;
    const isPrint = document.querySelector('input[name="order_original[is_print]"]:checked').value;
    const lot = parseInt(document.getElementById('order_original_lot').value);
    const deliveryTarget = document.getElementById('order_original_delivery_target').value;
    const deliveryArea = document.getElementById('order_original_lot_delivery_area').value;
    const isFoiled = document.getElementById('m_option_3').checked || document.getElementById('m_option_4').checked;
    const isInsideIsBlack = document.getElementById('m_option_5').checked;

    optionCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', function(event) {
        const optionName = this.dataset.name;
        if (optionName === 'foiled_silver' || optionName === 'foiled_gold') {
          // クリックされたチェックボックスの状態を維持
          this.checked = this.checked ? true : false;

          // もう一方のfoiledオプションのチェックを外す
          optionCheckboxes.forEach(cb => {
            if ((cb.dataset.name === 'foiled_silver' || cb.dataset.name === 'foiled_gold') && cb !== this) {
              cb.checked = false;
            }
          });
        }

        // updateOptionsState();
      });
    });

    // 結果の値を挿入
    const formDeliveryAmout = document.getElementById('js-formDeliveryAmount');
    const formBoxAmount = document.getElementById('js-formBoxAmount');
    const formTotalAmount = document.getElementById('js-formTotalAmount');


    // 材料の仕入れ値
    // 実質以下の2つの設定値を使用する
    const materialCosts = {
      // 箱内側を黒色に加工する場合は、ソコフタの生地仕入れ値を2倍に
      // 白菊ゼロ13号: isInsideIsBlack? 182.4 : 91.2,
      白菊ゼロ13号: 91.2,
      タント: 43.8,
    };
    const cardboardCost = 155;

    // 各パーツの丁取り数を計算する関数
    function calculateYield(vertical, horizontal, height, fullSheetSize) {
      const yield1 = Math.floor(fullSheetSize.height / (vertical + height * 2)) * Math.floor(fullSheetSize.width / (horizontal + height * 2));
      const yield2 = Math.floor(fullSheetSize.height / (horizontal + height * 2)) * Math.floor(fullSheetSize.width / (vertical + height * 2));
      return Math.max(yield1, yield2);
    }
    // 貼り紙の丁取り数を計算する関数
    function calculateYield2(vertical, horizontal, height, fullSheetSize) {
      const maxSize = Math.max(vertical, horizontal);
      const minSize = Math.min(vertical, horizontal);
      return Math.floor(fullSheetSize.height / minSize) * Math.floor(fullSheetSize.width / maxSize);
    }

    // 身生地の丁取り数を計算
    const bodyMaterialYield = calculateYield(vertical, horizontal, height, { height: 1100, width: 800 });
    console.log('身生地の丁取り', bodyMaterialYield);

    // 蓋生地の丁取り数を計算
    let lidVertical, lidHorizontal, lidHeight;
    // 浅フタの計算
    if (lidType === 'shallow') {
      lidVertical = vertical + 4;
      lidHorizontal = horizontal + 4;
      lidHeight = formLidHeight;
    // 深フタの計算
    } else {
      const lidEngagement = height <= 30 ? 4 : height <= 59 ? 5 : 6;
      lidVertical = vertical + lidEngagement;
      lidHorizontal = horizontal + lidEngagement;
      lidHeight = height - lidEngagement / 2;
    }
    console.log({lidHeight});
    const lidMaterialYield = calculateYield(lidVertical, lidHorizontal, lidHeight, { height: 1100, width: 800 });
    console.log('蓋生地の丁取り', lidMaterialYield);
    // 身貼紙の丁取り数を計算
    const bodyPaperYield = calculateYield2(vertical + height * 2 + 28, horizontal + height * 2 + 28, 0, { height: 1091, width: 788 });
    console.log('身貼紙の丁取り', bodyPaperYield);
    // 蓋貼紙の丁取り数を計算
    const lidPaperYield = calculateYield2(lidVertical + lidHeight * 2 + 28, lidHorizontal + lidHeight * 2 + 28, 0, { height: 1091, width: 788 });
    console.log('蓋貼紙の丁取り', lidPaperYield);
    // 蓋貼紙（印刷）の丁取り数を計算
    const lidPrintPaperYield = isPrint === 'enable' ? Math.max(
      Math.floor(308 / (lidVertical + lidHeight * 2 + 28)) * Math.floor(465 / (lidHorizontal + lidHeight * 2 + 28)),
      Math.floor(308 / (lidHorizontal + lidHeight * 2 + 28)) * Math.floor(465 / (lidVertical + lidHeight * 2 + 28))
    ) : null;
    console.log('蓋貼紙印刷の丁取り', lidPrintPaperYield);


    // ダンボールの必要数を計算
    const boxOuterSize = {
      width: lidVertical + 3,
      depth: lidHorizontal + 3,
      height: height + 3,
    };
    const cardboardSize = { width: 500, depth: 500, height: 530 };
    const cardboardCapacity = Math.max(
      Math.floor(cardboardSize.width / boxOuterSize.width) * Math.floor(cardboardSize.depth / boxOuterSize.depth) * Math.floor(cardboardSize.height / boxOuterSize.height),
      Math.floor(cardboardSize.width / boxOuterSize.width) * Math.floor(cardboardSize.depth / boxOuterSize.height) * Math.floor(cardboardSize.height / boxOuterSize.depth),
      Math.floor(cardboardSize.width / boxOuterSize.depth) * Math.floor(cardboardSize.depth / boxOuterSize.width) * Math.floor(cardboardSize.height / boxOuterSize.height),
      Math.floor(cardboardSize.width / boxOuterSize.depth) * Math.floor(cardboardSize.depth / boxOuterSize.height) * Math.floor(cardboardSize.height / boxOuterSize.width),
      Math.floor(cardboardSize.width / boxOuterSize.height) * Math.floor(cardboardSize.depth / boxOuterSize.depth) * Math.floor(cardboardSize.height / boxOuterSize.width),
      Math.floor(cardboardSize.width / boxOuterSize.height) * Math.floor(cardboardSize.depth / boxOuterSize.width) * Math.floor(cardboardSize.height / boxOuterSize.depth)
    );
    console.log({boxOuterSize});
    console.log({cardboardCapacity});
    const cardboardCount = Math.ceil(lot / cardboardCapacity);

    // 注文1個あたりの段ボール単価を計算
    const cardboardCostByBox = Math.ceil(((cardboardCount * cardboardCost / lot)) * 100) / 100;
    console.log('1個あたりのダンボール単価' ,cardboardCostByBox);

    // 原材料の原単価を計算
    let materialUnitCost;
    if (isPrint === 'disable') {
      materialUnitCost =
        Math.ceil(((materialCosts.白菊ゼロ13号 / lidMaterialYield)) * 100) / 100 +
        Math.ceil(((materialCosts.白菊ゼロ13号 / bodyMaterialYield)) * 100) / 100 +
        Math.ceil(((materialCosts.タント / lidPaperYield)) * 100) / 100 +
        Math.ceil(((materialCosts.タント / bodyPaperYield)) * 100) / 100 +
        cardboardCostByBox;
    } else {
      materialUnitCost =
        Math.ceil(((materialCosts.白菊ゼロ13号 / lidMaterialYield)) * 100) / 100 +
        Math.ceil(((materialCosts.白菊ゼロ13号 / bodyMaterialYield)) * 100) / 100 +
        Math.ceil((((materialCosts.タント / 4 + 15) / lidPrintPaperYield)) * 100) / 100 +
        Math.ceil(((materialCosts.タント / bodyPaperYield)) * 100) / 100 +
        cardboardCostByBox;
    }

    console.log('原単価', materialUnitCost);
    console.log('蓋生地の原単価', Math.ceil(((materialCosts.白菊ゼロ13号 / lidMaterialYield)) * 100) / 100);
    console.log('身生地の原単価', Math.ceil(((materialCosts.白菊ゼロ13号 / bodyMaterialYield)) * 100) / 100);
    console.log('蓋貼紙の原単価', Math.ceil(((materialCosts.タント / lidPaperYield)) * 100) / 100);
    console.log('蓋貼紙印刷の原単価', Math.ceil((((materialCosts.タント / 4 + 15) / lidPrintPaperYield)) * 100) / 100);
    console.log('身貼紙の原単価', Math.ceil(((materialCosts.タント / bodyPaperYield)) * 100) / 100);

    // 箱1個あたりの単価を計算
    let boxUnitPrice;
    const lidSurfaceLength = Math.max(lidVertical, lidHorizontal);
    const lidSideLength = lidType === 'shallow' ? lidHeight : height - 3;
    const lidTotalLength = lidSurfaceLength + lidSideLength * 2;
    console.log('天面', lidSurfaceLength);
    console.log('側面', lidSideLength);
    console.log({lidTotalLength});


    let coefficient;
    if (lot <= 30) {
      if (isPrint === 'enable' && lidTotalLength <= 200) {
        coefficient = 0.17;
      } else {
        coefficient = 0.2;
      }
    } else if (lot <= 50) {
      if (isPrint === 'enable' && lidTotalLength <= 200) {
        coefficient = 0.18;
      } else {
        coefficient = 0.21;
      }
    } else if (lot <= 100) {
      if (isPrint === 'enable' && lidTotalLength <= 200) {
        coefficient = 0.2;
      } else {
        coefficient = 0.22;
      }
    } else if (lot <= 200) {
      if (isPrint === 'enable' && lidTotalLength <= 200) {
        coefficient = 0.22;
      } else {
        coefficient = 0.23;
      }
    } else {
      if (isPrint === 'enable' && lidTotalLength <= 200) {
        coefficient = 0.23;
      } else {
        coefficient = 0.24;
      }
    }
    console.log('掛け率', coefficient);

    const isBlackOptionPrice = isInsideIsBlack ? 5000 : 0;
    const isFoiledOptionPrice = isFoiled ? 2000 : 0;

    const optionTotal = isBlackOptionPrice + isFoiledOptionPrice;
    console.log('オプション', optionTotal);

    boxUnitPrice = Math.ceil(materialUnitCost * 1.1 / coefficient) + Math.ceil(optionTotal / lot);
    console.log({boxUnitPrice});
    // 配送料を計算
    let deliveryFee;
    if (deliveryArea === '北海道') {
      deliveryFee = deliveryTarget === 'corporate' ? 1700 : 3100;
    } else if (['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県'].includes(deliveryArea)) {
      deliveryFee = deliveryTarget === 'corporate' ? 1500 : 2700;
    } else if (['新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '鳥取県', '島根県', '岡山県', '広島県', '山口県'].includes(deliveryArea)) {
      deliveryFee = deliveryTarget === 'corporate' ? 900 : 2500;
    } else if (['茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県'].includes(deliveryArea)) {
      deliveryFee = deliveryTarget === 'corporate' ? 1000 : 2500;
    } else if (['滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '徳島県', '香川県', '愛媛県', '高知県'].includes(deliveryArea)) {
      deliveryFee = deliveryTarget === 'corporate' ? 800 : 2500;
    } else {
      deliveryFee = 0; // 沖縄は要確認のため、とりあえず0としています
    }

    // 合計金額を計算
    const originalTotalAmount = boxUnitPrice * lot;
    let totalAmount = boxUnitPrice * lot;
    if (totalAmount < 10000) {
      totalAmount += deliveryFee * cardboardCount; // 1万円未満の場合は配送料を加算
    }

    // 計算結果を表示
    displayResult('js-boxAmount', boxUnitPrice, '0');
    displayResult('js-deliveryAmount', originalTotalAmount >= 10000 ? 0 : deliveryFee * cardboardCount, '0');
    displayResult('js-totalAmount', totalAmount, '0');

    displayClonedResult('boxAmountClone', boxUnitPrice);
    displayClonedResult('deliveryAmountClone', originalTotalAmount >= 10000 ? 0 : deliveryFee * cardboardCount);
    displayClonedResult('totalAmountClone', totalAmount);

    formDeliveryAmout.value = totalAmount >= 10000 ? '0' : deliveryFee * cardboardCount;
    formBoxAmount.value = boxUnitPrice;
    formTotalAmount.value = totalAmount;
  }

  function displayClonedResult(elementId, value) {
    const element = document.getElementById(elementId);
    if (element) {
      element.textContent = value ? value.toLocaleString() : '0';
    }
  }

  // 入力フォームの値が変更されたら自動見積もりを実行
  document.getElementById('js-originalNewForm').addEventListener('change', calculateEstimate);
  window.addEventListener('load', () => {
    // window.scrollTo(0, 0);
    calculateEstimate();
  });
});

/**
 * オリジナルで作る(別注)：仕様の入力
 * - 3Dプレビュー(身箱)
 */
document.addEventListener('DOMContentLoaded', () => {
  const canvas = document.getElementById('preview');
  if (canvas) {
    const widthInput = document.getElementById('order_original_horizontal');
    const heightInput = document.getElementById('order_original_height');
    const depthInput = document.getElementById('order_original_vertical');
    const ctx = canvas.getContext('2d');

    function drawBox() {
      const width = parseInt(widthInput.value);
      const height = parseInt(heightInput.value);
      const depth = parseInt(depthInput.value) / 1.5;

      // canvasをクリア
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // 3Dボックスを描画
      const angle = Math.PI / 6;
      const cosAngle = Math.cos(angle);
      const sinAngle = Math.sin(angle);

      // スケーリング係数を計算
      const maxDimension = Math.max(width, height, depth);
      const scale = Math.min(
        canvas.width / (maxDimension * (1 + cosAngle)),
        canvas.height / (maxDimension * (1 + sinAngle))
      ) * 0.8;

      const offsetX = (canvas.width - (width + depth * cosAngle) * scale) / 2;
      const offsetY = canvas.height - (height / 2 * scale + depth * sinAngle * scale);

      // 背面の描画
      ctx.beginPath();
      ctx.moveTo(offsetX + depth * cosAngle * scale, offsetY - (height + depth * sinAngle) * scale);
      ctx.lineTo(offsetX + (width + depth * cosAngle) * scale, offsetY - (height + depth * sinAngle) * scale);
      ctx.lineTo(offsetX + (width + depth * cosAngle) * scale, offsetY - depth * sinAngle * scale);
      ctx.lineTo(offsetX + depth * cosAngle * scale, offsetY - depth * sinAngle * scale);
      ctx.closePath();
      ctx.fillStyle = "#ccc";
      ctx.fill();

      // 右側面の描画
      ctx.beginPath();
      ctx.moveTo(offsetX + width * scale, offsetY);
      ctx.lineTo(offsetX + (width + depth * cosAngle) * scale, offsetY - depth * sinAngle * scale);
      ctx.lineTo(offsetX + (width + depth * cosAngle) * scale, offsetY - (height + depth * sinAngle) * scale);
      ctx.lineTo(offsetX + width * scale, offsetY - height * scale);
      ctx.closePath();
      ctx.fillStyle = "#ccc";
      ctx.fill();

      // 上面の描画
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      ctx.lineTo(offsetX + width * scale, offsetY);
      ctx.lineTo(offsetX + (width + depth * cosAngle) * scale, offsetY - depth * sinAngle * scale);
      ctx.lineTo(offsetX + depth * cosAngle * scale, offsetY - depth * sinAngle * scale);
      ctx.closePath();
      ctx.fillStyle = "#ccc";
      ctx.fill();

      // 左側面の描画
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      ctx.lineTo(offsetX + depth * cosAngle * scale, offsetY - depth * sinAngle * scale);
      ctx.lineTo(offsetX + depth * cosAngle * scale, offsetY - (height + depth * sinAngle) * scale);
      ctx.lineTo(offsetX, offsetY - height * scale);
      ctx.closePath();
      ctx.fillStyle = "#ccc";
      ctx.fill();

      // 前面の描画
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      ctx.lineTo(offsetX + width * scale, offsetY);
      ctx.lineTo(offsetX + width * scale, offsetY - height * scale);
      ctx.lineTo(offsetX, offsetY - height * scale);
      ctx.closePath();
      ctx.fillStyle = "#ccc";
      ctx.fill();
      ctx.strokeStyle = 'white';
      ctx.stroke();

      // 右側面の上辺を描画
      ctx.beginPath();
      ctx.moveTo(offsetX + width * scale, offsetY - height * scale);
      ctx.lineTo(offsetX + (width + depth * cosAngle) * scale, offsetY - (height + depth * sinAngle) * scale);
      ctx.strokeStyle = 'white';
      ctx.stroke();
    }

    // 入力値が変更された時にdrawBoxを呼び出す
    widthInput.addEventListener('input', drawBox);
    heightInput.addEventListener('input', drawBox);
    depthInput.addEventListener('input', drawBox);

    // 初期描画
    drawBox();
  }
});

/**
 * デザインから選ぶ(季節の貼り箱)：仕様の入力・ロゴのレイアウト
 * - ロゴの有無によるactionの制御
 * - ロゴレイアウトフォームの処理
 */
document.addEventListener("DOMContentLoaded", () => {
  const designNewForm = document.getElementById("js-designNewForm");
  const formSubmit = document.getElementById('js-formSubmit');
  const logoLayoutForm = document.getElementById("js-logoLayoutNewForm");
  const isLogoRadioButtons = document.querySelectorAll(
    'input[name="order_template[is_logo]"]'
  );
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  const fileInput = document.getElementById("logo");
  const box = document.getElementById("box");
  const preview = document.getElementById("previewImage");
  const expansionButton = document.getElementById("expansion");
  const reductionButton = document.getElementById("reduction");
  const transmissionButton = document.getElementById("js-transmission");
  const detailsButton = document.getElementById("details");
  const detailsContainer = document.querySelector(".p-contents__details");
  const closeButton = document.querySelector(".close");
  const resetButton = document.querySelector(".p-contents__reset");

  const boxSizeSelect = document.getElementById('js-boxSize');
  const templateSelect = document.getElementById('order_template_template_item_id');
  const designList = document.getElementById('design-list');
  const listItems = designList?.querySelectorAll('.p-list__item');

  /**
   * 選択された箱サイズに合わせて蓋のデザインオプションを更新
   */
  const updateTemplateOptions = () => {
    const selectedBoxSize = boxSizeSelect.value;
    const currentSelectedValue = templateSelect.value;
    let optionsExist = templateSelect.options.length > 1;

    // オプションが存在しない場合のみ、初期オプションを追加
    if (!optionsExist) {
      templateSelect.innerHTML = '<option value="">選択してください</option>';
    }

    // リストアイテムとオプションを更新
    listItems.forEach(item => {
      const itemBoxSize = item.getAttribute('data-box-size');
      const itemId = item.getAttribute('data-id');
      let option;

      if (!optionsExist) {
        // オプションが存在しない場合は新しく作成
        option = document.createElement('option');
        option.value = itemId;
        option.text = item.getAttribute('data-name');
        templateSelect.appendChild(option);
      } else {
        // 既存のオプションを取得
        option = templateSelect.querySelector(`option[value="${itemId}"]`);
      }

      // 表示/非表示の設定
      if (selectedBoxSize === itemBoxSize) {
        option.style.display = '';
        item.style.display = 'block';
      } else {
        option.style.display = 'none';
        item.style.display = 'none';
      }
    });

    // 選択されていた値が新しいオプションリストに存在する場合、その値を再選択
    if (currentSelectedValue) {
      const optionStillExists = Array.from(templateSelect.options).some(opt => opt.value === currentSelectedValue && opt.style.display !== 'none');
      if (optionStillExists) {
        templateSelect.value = currentSelectedValue;
      } else {
        templateSelect.value = '';
      }
    }
  };

  /**
   * リストアイテムをクリックしたときに対応するオプションを選択する
   */
  const selectOptionOnItemClick = () => {
    listItems.forEach(item => {
      item.addEventListener('click', () => {
        const itemId = item.getAttribute('data-id');
        const option = templateSelect.querySelector(`option[value="${itemId}"]`);

        if (option && option.style.display !== 'none') {
          templateSelect.value = itemId;

          // listItems.forEach(li => li.classList.remove('is-active'));
          // item.classList.add('is-active');
          // カスタムイベントをディスパッチして、選択が変更されたことを通知
          const event = new Event('change', { bubbles: true });
          templateSelect.dispatchEvent(event);
        }
      });
    });
  };

  /**
   * color-listの項目をクリックした時にセレクトボックスを更新する
   */
  const updateColorSelectOnItemClick = () => {
    const colorSelect = document.getElementById('order_template_m_box_color_id');
    const colorList = document.getElementById('color-list');
    const colorItems = colorList.querySelectorAll('.p-list__item');

    colorItems.forEach(item => {
      item.addEventListener('click', () => {
        const colorName = item.getAttribute('color-category');

        // セレクトボックスのオプションを探す
        const option = Array.from(colorSelect.options).find(opt => opt.text === colorName);

        if (option) {
          // オプションが見つかった場合、それを選択する
          colorSelect.value = option.value;

          // is-activeクラスの更新
          colorItems.forEach(ci => ci.classList.remove('is-active'));
          item.classList.add('is-active');

          // changeイベントをディスパッチ
          const event = new Event('change', { bubbles: true });
          colorSelect.dispatchEvent(event);
        }
      });
    });
  };

  // セレクトボックスの変更時にcolor-listのアクティブ状態を更新する
  const updateActiveColorItem = () => {
    const colorSelect = document.getElementById('order_template_m_box_color_id');
    const colorList = document.getElementById('color-list');
    const colorItems = colorList.querySelectorAll('.p-list__item');

    colorSelect.addEventListener('change', () => {
      const selectedColorName = colorSelect.options[colorSelect.selectedIndex].text;
      
      colorItems.forEach(item => {
        const itemColorName = item.getAttribute('color-category');
        if (itemColorName === selectedColorName) {
          item.classList.add('is-active');
        } else {
          item.classList.remove('is-active');
        }
      });
    });
  };

  let isDragging = false;
  let isResizing = false;
  let initialWidth = 0;
  let initialHeight = 0;
  let initialAspectRatio = 0;
  let setX = 0;
  let setY = 0;

  // ロゴの有無によるフォームのアクションの切り替え
  if (designNewForm) {
    const optionCheckboxes = document.querySelectorAll('.option-check');
    updateTemplateOptions();
    window.addEventListener('load', () => {
      const selectedValue = document.querySelector(
        'input[name="order_template[is_logo]"]:checked'
      ).value;
      designNewForm.action = selectedValue === "enable" ? "/templates/orders/logo_layout" : "/templates/orders/edit_design";
    });
    window.addEventListener('load', updateTemplateOptions);
    window.addEventListener('load',  selectOptionOnItemClick);
    boxSizeSelect.addEventListener('change', updateTemplateOptions);
    window.addEventListener('load', updateColorSelectOnItemClick);
    window.addEventListener('load', updateActiveColorItem);

    isLogoRadioButtons.forEach((isLogoRadio) => {
      isLogoRadio.addEventListener("change", () => {
        const selectedValue = document.querySelector(
          'input[name="order_template[is_logo]"]:checked'
        ).value;
        designNewForm.action = selectedValue === "enable" ? "/templates/orders/logo_layout" : "/templates/orders/edit_design";
      });
    });

    /**
     * フォームのエンターキーと送信時のバリデーション表示
     */
    // Enter押下時に送信させない
    designNewForm.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        const target = event.target;
        if (target.tagName === 'INPUT' || target.tagName === 'SELECT' || target.tagName === 'TEXTAREA') {
          event.preventDefault();
          const validity = target.validity;
          if (!validity.valid) {
            target.reportValidity();
          }
        }
      }
    });
    // 送信時のバリデーション
    formSubmit?.addEventListener('click', (event) => {
      const fields = Array.from(designNewForm.querySelectorAll('input[type="number"], input[type="text"][required], select.js-selectRequired'));

      let isValid = true;
      let firstInvalidElement = null;

      fields.forEach((field) => {
        const errorMessage = field.closest('.p-design__row').querySelector('.c-form__error');
        if (field.tagName === 'SELECT' && field.value === '') {
          isValid = false;
          errorMessage.textContent = '選択してください';
          errorMessage.style.display = 'block';
          if (!firstInvalidElement) {
            firstInvalidElement = field;
          }
        } else if (!field.checkValidity()) {
          isValid = false;
          errorMessage.textContent = field.validationMessage;
          errorMessage.style.display = 'block';
          if (!firstInvalidElement) {
            firstInvalidElement = field;
          }
        } else {
          errorMessage.textContent = '';
          errorMessage.style.display = 'none';
        }
      });

      if (!isValid) {
        event.preventDefault();
        firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      const area = document.getElementById('order_template_delivery_area').value;
      const address = document.getElementById('order_template_delivery_address').value;
      if (!address.includes(area)) {
        event.preventDefault();
        alert('配送先地域と配送先住所が一致していません。');
      }

      // 沖縄県の場合は送料が着払いであることを確認
      if (area === '沖縄県') {
        event.preventDefault();  // フォーム送信を一時的に停止
        if (confirm('沖縄は別途、配送料のみ着払いでの対応となります。よろしいですか？')) {
          // OKの場合、フォームを送信
          designNewForm.submit();
        }
        // キャンセルの場合は何もせず、フォーム送信を中止
      }
    });

    function calculateEstimate() {
      // フォームの入力値を取得
      const boxSize = document.getElementById('js-boxSize').value;
      const deliveryTarget = document.getElementById('order_template_delivery_target').value;
      const deliveryArea = document.getElementById('order_template_delivery_area').value;
      const lot = parseInt(document.getElementById('order_template_lot').value);
      const isLogo = document.querySelector('input[name="order_template[is_logo]"]:checked').value;
      const isEmbossed = document.getElementById('m_option_3').checked || document.getElementById('m_option_4').checked;
      const isInsideIsBlack = document.getElementById('m_option_5').checked;

      // 結果の値を挿入
      const formDeliveryAmout = document.getElementById('js-formDeliveryAmount');
      const formBoxAmount = document.getElementById('js-formBoxAmount');
      const formTotalAmount = document.getElementById('js-formTotalAmount');

      // 箔押しの選択を単一にする
      optionCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', function(event) {
          const optionName = this.dataset.name;
          if (optionName === 'foiled_silver' || optionName === 'foiled_gold') {
            // クリックされたチェックボックスの状態を維持
            this.checked = this.checked ? true : false;

            // もう一方のfoiledオプションのチェックを外す
            optionCheckboxes.forEach(cb => {
              if ((cb.dataset.name === 'foiled_silver' || cb.dataset.name === 'foiled_gold') && cb !== this) {
                cb.checked = false;
              }
            });
          }

          // updateOptionsState();
        });
      });

      // 箱のサイズごとの単価一覧
      const boxSizes = {
        s: { isLogoAndisInsideBlack: 391, isLogo: 253, isInsideBlack: 386, normal: 248 },
        k: { isLogoAndisInsideBlack: 391, isLogo: 253, isInsideBlack: 386, normal: 248 },
        m: { isLogoAndisInsideBlack: 417, isLogo: 283, isInsideBlack: 412, normal: 278 },
        l: { isLogoAndisInsideBlack: 582, isLogo: 373, isInsideBlack: 577, normal: 368 },
      };

      let unitPrice = 0;
      if (isLogo === 'enable' && isInsideIsBlack) {
        unitPrice = boxSizes[boxSize].isLogoAndisInsideBlack;
      } else if (isLogo === 'enable') {
        unitPrice = boxSizes[boxSize].isLogo;
      } else if (isInsideIsBlack) {
        unitPrice = boxSizes[boxSize].isInsideBlack;
      } else {
        unitPrice = boxSizes[boxSize].normal;
      }

      // オプション料金の計算
      let optionFee = 0;
      if (isEmbossed) {
        if (lot <= 100) {
          optionFee = 2000;
        } else {
          optionFee = 2000 + (lot - 100) * 20;
        }
      }

      // ダンボールの個数を計算
      let cardboardCount = 1;
      switch (boxSize) {
        case 's':
          if (lot <= 70) {
            cardboardCount = 1;
          } else if (lot <= 140) {
            cardboardCount = 2;
          } else if (lot <= 210) {
            cardboardCount = 3;
          } else if (lot <= 280) {
            cardboardCount = 4;
          } else if (lot <= 300) {
            cardboardCount = 5;
          }
          break;
        case 'k':
          if (lot <= 75) {
            cardboardCount = 1;
          } else if (lot <= 150) {
            cardboardCount = 2;
          } else if (lot <= 225) {
            cardboardCount = 3;
          } else if (lot <= 300) {
            cardboardCount = 4;
          }
          break;
        case 'm':
            if (lot <= 40) {
              cardboardCount = 1;
            } else if (lot <= 80) {
              cardboardCount = 2;
            } else if (lot <= 120) {
              cardboardCount = 3;
            } else if (lot <= 160) {
              cardboardCount = 4;
            } else if (lot <= 200) {
              cardboardCount = 5;
            } else if (lot <= 240) {
              cardboardCount = 6;
            } else if (lot <= 280) {
              cardboardCount = 7;
            } else if (lot <= 300) {
              cardboardCount = 8;
            }
          break;
        case 'l':
          if (lot <= 30) {
            cardboardCount = 1;
          } else if (lot <= 60) {
            cardboardCount = 2;
          } else if (lot <= 90) {
            cardboardCount = 3;
          } else if (lot <= 120) {
            cardboardCount = 4;
          } else if (lot <= 150) {
            cardboardCount = 5;
          } else if (lot <= 180) {
            cardboardCount = 6;
          } else if (lot <= 210) {
            cardboardCount = 7;
          } else if (lot <= 240) {
            cardboardCount = 8;
          } else if (lot <= 270) {
            cardboardCount = 9;
          } else if (lot <= 300) {
            cardboardCount = 10;
          }
          break;
        default:
          // boxSizeの値が不正な場合
          cardboardCount = 1;
          break;
      }

      // 配送料を計算
      let deliveryFee;
      if (deliveryArea === '北海道') {
        deliveryFee = deliveryTarget === 'corporate' ? 1700 : 3100;
      } else if (['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県'].includes(deliveryArea)) {
        deliveryFee = deliveryTarget === 'corporate' ? 1500 : 2700;
      } else if (['新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '鳥取県', '島根県', '岡山県', '広島県', '山口県'].includes(deliveryArea)) {
        deliveryFee = deliveryTarget === 'corporate' ? 900 : 2500;
      } else if (['茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県'].includes(deliveryArea)) {
        deliveryFee = deliveryTarget === 'corporate' ? 1000 : 2500;
      } else if (['滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '徳島県', '香川県', '愛媛県', '高知県'].includes(deliveryArea)) {
        deliveryFee = deliveryTarget === 'corporate' ? 800 : 2500;
      } else {
        deliveryFee = 0; // 沖縄は要確認のため、とりあえず0としています
      }

      // 合計金額の計算
      let totalAmount = unitPrice * lot + optionFee;
      const originaltotalAmount = unitPrice * lot + optionFee;
      if (originaltotalAmount < 10000) {
        totalAmount = originaltotalAmount + deliveryFee * cardboardCount; // 1万円未満の場合は配送料を加算
      }

      // 計算結果を表示
      document.getElementById('js-boxAmount').textContent = unitPrice.toLocaleString();
      document.getElementById('js-deliveryAmount').textContent = originaltotalAmount >= 10000 ? '0' : deliveryFee * cardboardCount;
      document.getElementById('js-totalAmount').textContent = totalAmount.toLocaleString();

      displayClonedResult('boxAmountClone', unitPrice);
      displayClonedResult('deliveryAmountClone', originaltotalAmount >= 10000 ? 0 : deliveryFee * cardboardCount);
      displayClonedResult('totalAmountClone', totalAmount);
      formDeliveryAmout.value = originaltotalAmount >= 10000 ? '0' : deliveryFee * cardboardCount;
      formBoxAmount.value = unitPrice;
      formTotalAmount.value = totalAmount;

    }

    function displayClonedResult(elementId, value) {
      const element = document.getElementById(elementId);
      if (element) {
        element.textContent = value ? value.toLocaleString() : '0';
      }
    }
    // フォームの入力値が変更された際に自動見積もりを実行
    document.getElementById('js-designNewForm').addEventListener('change', calculateEstimate);
    // 画面読み込み時に自動見積もりを実行
     window.addEventListener('load', () => {
      // window.scrollTo(0, 0);
      calculateEstimate();
    });
  }
  if (logoLayoutForm) {
    // プレビュー画像のcss
    const templatePreview = document.getElementById("js-templatePreview");
    var imageUrl = templatePreview.getAttribute("data-src");

    // クロスオリジンを設定してから画像を表示
    if (templatePreview && imageUrl) {
      templatePreview.crossOrigin = "anonymous";
      templatePreview.src = imageUrl;
    }
    // アスペクト比に応じたスタイル設定
    templatePreview.onload = function() {
      const aspectRatio = templatePreview.naturalWidth / templatePreview.naturalHeight;
      if (aspectRatio > 1) {
        // 横長
        templatePreview.style.width = '100%';
        templatePreview.style.height = 'auto';
      } else {
        // 縦長
        templatePreview.style.width = 'auto';
        templatePreview.style.height = '100%';
      }
    };

    // 画像がキャッシュから読み込まれる場合に備えて
    if (templatePreview.complete) {
      templatePreview.onload();
    }


    // 画像の透過処理
    const processImage = (imageSrc) => {
      fetch(imageSrc)
        .then((response) => response.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append('image', blob, 'image.png');

          fetch("/templates/image/transmission", {
            credentials: "same-origin",
            headers: { "X-CSRF-Token": token },
            method: "POST",
            body: formData,
          })
            .then((response) => response.blob())
            .then((blob) => {
              const imageUrl = URL.createObjectURL(blob);
              document.getElementById("image").src = imageUrl;
            });
        });
    };

    // ロゴのプレビュー表示
    const previewFile = (file) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const imageUrl = e.target.result;
        const img = document.createElement("img");

        fetch(imageUrl)
          .then(res => res.blob())
          .then(blob => {
            const shortFileName = 'image.png';
            const shortImageUrl = URL.createObjectURL(blob);

            img.setAttribute('data-filename', shortFileName);
            img.src = shortImageUrl;
            img.id = "image";
            preview.appendChild(img);
            preview.classList.add("view");
          });
      };

      reader.readAsDataURL(file);
    };

    // ファイル選択時の処理
    fileInput.addEventListener("change", () => {
      const files = fileInput.files;
      for (let i = 0; i < files.length; i++) {
        previewFile(files[i]);
      }
    });

    // ロゴの移動
    // preview.addEventListener("mousedown", (e) => {
    //   isDragging = true;
    //   const offsetX = e.clientX - preview.getBoundingClientRect().left;
    //   const offsetY = e.clientY - preview.getBoundingClientRect().top;

    //   document.addEventListener("mousemove", (e) => {
    //     if (isDragging) {
    //       const x = e.clientX - offsetX - templatePreview.getBoundingClientRect().left;
    //       const y = e.clientY - offsetY - templatePreview.getBoundingClientRect().top;

    //       preview.style.left = x + "px";
    //       preview.style.top = y + "px";
    //     }
    //   });

    //   document.addEventListener("mouseup", () => {
    //     isDragging = false;
    //   });
    // });
    function startDragging(e) {
      if (isResizing) return; 
      isDragging = true;
      offsetX = e.clientX - preview.getBoundingClientRect().left;
      offsetY = e.clientY - preview.getBoundingClientRect().top;

      document.addEventListener("mousemove", moveLogo);
      document.addEventListener("mouseup", stopDragging);
    }

    function moveLogo(e) {
      if (isDragging) {
        const bgRect = templatePreview.getBoundingClientRect();
        const previewRect = preview.getBoundingClientRect();

        let x = e.clientX - offsetX - templatePreview.getBoundingClientRect().left;
        let y = e.clientY - offsetY - templatePreview.getBoundingClientRect().top;

        // 移動範囲の制限
        x = Math.max(0, Math.min(x, bgRect.width - previewRect.width));
        y = Math.max(0, Math.min(y, bgRect.height - previewRect.height));

        preview.style.left = x + "px";
        preview.style.top = y + "px";
      }
    }

    function stopDragging() {
      isDragging = false;
      document.removeEventListener("mousemove", moveLogo);
      document.removeEventListener("mouseup", stopDragging);
    }

    // イベントリスナーの設定
    preview.addEventListener("mousedown", startDragging);

    // ロゴのリサイズ
    // document.querySelectorAll(".resize-handle").forEach((handle) => {
    //   handle.addEventListener("mousedown", (e) => {
    //     const image = document.getElementById("image");
    //     isResizing = true;
    //     initialWidth = image.offsetWidth;
    //     initialHeight = image.offsetHeight;
    //     setX = e.clientX;
    //     setY = e.clientY;
    //     e.preventDefault();
    //   });
    // });

    document.querySelectorAll(".resize-handle").forEach((handle) => {
      handle.addEventListener("mousedown", (e) => {
        const image = document.getElementById("image");
        isResizing = true;
        initialWidth = image.offsetWidth;
        initialHeight = image.offsetHeight;
        initialAspectRatio = initialWidth / initialHeight;
        setX = e.clientX;
        setY = e.clientY;
        e.preventDefault();
    
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      });
    });
    function handleMouseMove(e) {
      if (isResizing) {
        const image = document.getElementById("image");
        const bgRect = templatePreview.getBoundingClientRect();
        const deltaX = e.clientX - setX;
        const deltaY = e.clientY - setY;

        // マウスの移動方向に応じて、幅または高さを優先して変更
        let newWidth, newHeight;
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          newWidth = Math.max(10, Math.min(initialWidth + deltaX, bgRect.width));
          newHeight = newWidth / initialAspectRatio;
        } else {
          newHeight = Math.max(10, Math.min(initialHeight + deltaY, bgRect.height));
          newWidth = newHeight * initialAspectRatio;
        }

        // 背景画像の範囲内に収まるように調整
        if (newWidth > bgRect.width) {
          newWidth = bgRect.width;
          newHeight = newWidth / initialAspectRatio;
        }
        if (newHeight > bgRect.height) {
          newHeight = bgRect.height;
          newWidth = newHeight * initialAspectRatio;
        }

        image.style.width = `${newWidth}px`;
        image.style.height = `${newHeight}px`;
      }
    }

    function handleMouseUp() {
      isResizing = false;
      // マウスの移動イベントリスナーを削除
      document.removeEventListener("mousemove", handleMouseMove);
      // マウスのボタンを離したときのイベントリスナーを削除
      document.removeEventListener("mouseup", handleMouseUp);
    }

    // 既存のmousemoveイベントリスナーを削除
    // document.removeEventListener("mousemove", /* 既存の関数名 */);

    // // 既存のmouseupイベントリスナーを削除
    // document.removeEventListener("mouseup", /* 既存の関数名 */);

    // document.addEventListener("mousemove", (e) => {
    //   if (isResizing) {
    //     const deltaX = e.clientX - setX;
    //     const deltaY = e.clientY - setY;
    //     const image = document.getElementById("image");

    //     let newWidth = initialWidth + deltaX;
    //     let newHeight = initialHeight + deltaY;

    //     if (newWidth > 10 && newHeight > 10) {
    //       const aspectRatio = initialWidth / initialHeight;
    //       newHeight = newWidth / aspectRatio;

    //       image.style.width = newWidth + "px";
    //       image.style.height = newHeight + "px";
    //     }
    //   }
    // });

    // document.addEventListener("mouseup", () => {
    //   isResizing = false;
    // });

    // // 拡大・縮小ボタンの処理
    // const resizeImage = (delta) => {
    //   const image = document.getElementById("image");
    //   const currentWidth = image.clientWidth;
    //   const currentHeight = image.clientHeight;
    //   const newWidth = currentWidth + delta;
    //   const newHeight = currentHeight + delta;

    //   if (newWidth >= 10 && newHeight >= 10) {
    //     image.style.width = newWidth + "px";
    //     image.style.height = newHeight + "px";
    //   }
    // };
    // document.addEventListener("mousemove", (e) => {
    //   if (isResizing) {
    //     const deltaX = e.clientX - setX;
    //     const image = document.getElementById("image");
    //     const aspectRatio = initialWidth / initialHeight;

    //     let newWidth = initialWidth + deltaX;
    //     let newHeight = newWidth / aspectRatio;

    //     // 最小サイズの制限
    //     if (newWidth < 10 || newHeight < 10) {
    //       return;
    //     }

    //     // 最大サイズの制限（背景画像のサイズを超えないように）
    //     const boxRect = box.getBoundingClientRect();
    //     newWidth = Math.min(newWidth, boxRect.width);
    //     newHeight = Math.min(newHeight, boxRect.height);

    //     image.style.width = newWidth + "px";
    //     image.style.height = newHeight + "px";
    //   }
    // });

    // 拡大・縮小ボタンの処理も同様に修正
    // const resizeImage = (delta) => {
    //   const image = document.getElementById("image");
    //   const currentWidth = image.clientWidth;
    //   const aspectRatio = image.naturalWidth / image.naturalHeight;

    //   let newWidth = currentWidth + delta;
    //   let newHeight = newWidth / aspectRatio;

    //   // 最小サイズと最大サイズの制限
    //   const bgRect = templatePreview.getBoundingClientRect();
    //   newWidth = Math.max(10, Math.min(newWidth, bgRect.width));
    //   newHeight = Math.max(10, Math.min(newHeight, bgRect.height));

    //   image.style.width = newWidth + "px";
    //   image.style.height = newHeight + "px";
    // };

    // expansionButton.addEventListener("click", () => resizeImage(10));
    // reductionButton.addEventListener("click", () => resizeImage(-10));

    // 拡大・縮小ボタンの処理（オプション）
    function resizeImageByButton(growFactor) {
      const image = document.getElementById("image");
      const bgRect = templatePreview.getBoundingClientRect();
      const imageRect = image.getBoundingClientRect();

      // 現在のアスペクト比を計算
      const aspectRatio = imageRect.width / imageRect.height;

      // 新しい寸法を計算（アスペクト比を維持）
      let newWidth = imageRect.width * growFactor;
      let newHeight = newWidth / aspectRatio;

      // 背景画像の範囲内に収まるように調整
      if (newWidth > bgRect.width) {
        newWidth = bgRect.width;
        newHeight = newWidth / aspectRatio;
      }
      if (newHeight > bgRect.height) {
        newHeight = bgRect.height;
        newWidth = newHeight * aspectRatio;
      }

      // 最小サイズの制限
      if (newWidth < 10 || newHeight < 10) {
        return; // サイズが小さすぎる場合は何もしない
      }

      // 画像が背景からはみ出さないように位置を調整
      let left = imageRect.left - bgRect.left;
      let top = imageRect.top - bgRect.top;
      if (left + newWidth > bgRect.width) left = bgRect.width - newWidth;
      if (top + newHeight > bgRect.height) top = bgRect.height - newHeight;
      left = Math.max(0, left);
      top = Math.max(0, top);

      // 新しいサイズと位置を適用
      image.style.width = `${newWidth}px`;
      image.style.height = `${newHeight}px`;
      image.style.left = `${left}px`;
      image.style.top = `${top}px`;
    }

    // 拡大・縮小ボタンのイベントリスナー（オプション）
    expansionButton.addEventListener("click", () => resizeImageByButton(1.1)); // 10%拡大
    reductionButton.addEventListener("click", () => resizeImageByButton(0.9));

    // 透過ボタンの処理
    transmissionButton.addEventListener("click", () => {
      box.classList.add("transmission");
      processImage(document.getElementById("image").src);
    });

    // 詳細表示ボタンの処理
    detailsButton.addEventListener("click", () => {
      detailsContainer.classList.add("view");
    });

    // 閉じるボタンの処理
    closeButton.addEventListener("click", () => {
      detailsContainer.classList.remove("view");
    });

    // リセットボタンの処理
    resetButton.addEventListener("click", () => {
      // 現在のプレビュー画像を削除
      const previewImage = document.getElementById("image");
      if (previewImage) {
        previewImage.remove();
      }

      // プレビューコンテナをリセット
      preview.style.left = "0px";
      preview.style.top = "0px";
      preview.classList.remove("view");

      // ファイル入力をリセット
      const fileInput = document.getElementById("js-logoLayoutNewFormFileInput");
      fileInput.value = "";

      // 透過処理をリセット（必要に応じて）
      box.classList.remove("transmission");

      // ファイル入力にフォーカスを当てる（オプション）
      fileInput.focus();
    });

    // 画像の合成とダウンロード
    const downloadButton = document.getElementById("downloadButton");

    const combineImages = () => {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const backgroundImage = new Image();

        backgroundImage.crossOrigin = "anonymous";
        backgroundImage.src = templatePreview.src;

        backgroundImage.onload = () => {
          // 元の画像サイズを使用
          canvas.width = backgroundImage.naturalWidth;
          canvas.height = backgroundImage.naturalHeight;
          ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
          resolve({ canvas, backgroundImage });
        };

        backgroundImage.onerror = (error) => {
          console.error("Error loading image: ", error);
          reject(error);
        };
      });
    };
    const downloadSVG = () => {
      return new Promise((resolve, reject) => {
        combineImages().then(({ canvas, backgroundImage }) => {
          const logoImage = document.getElementById("image");
          const box = document.getElementById("box");
          logoImage.crossOrigin = "Anonymous";
          
          logoImage.onload = () => {
            const logoRect = logoImage.getBoundingClientRect();
            const boxRect = box.getBoundingClientRect();
            
            // 背景画像のスケーリング係数を計算
            const bgScaleX = backgroundImage.naturalWidth / boxRect.width;
            const bgScaleY = backgroundImage.naturalHeight / boxRect.height;
            
            // ロゴのスケーリング係数を計算
            const logoScaleX = logoImage.naturalWidth / logoRect.width;
            const logoScaleY = logoImage.naturalHeight / logoRect.height;
            
            // ロゴの実際の位置とサイズを計算
            const logoX = Math.round((logoRect.left - boxRect.left) * bgScaleX);
            const logoY = Math.round((logoRect.top - boxRect.top) * bgScaleY);
            const logoHeight = Math.round(logoRect.height * bgScaleY);
            
            // アスペクト比を維持した横幅を計算
            const logoAspectRatio = logoImage.naturalWidth / logoImage.naturalHeight;
            const logoWidth = Math.round(logoHeight * logoAspectRatio);
      
            // ロゴを元の解像度でキャンバスに描画
            const logoCanvas = document.createElement("canvas");
            logoCanvas.width = logoImage.naturalWidth;
            logoCanvas.height = logoImage.naturalHeight;
            const logoCtx = logoCanvas.getContext("2d");
            logoCtx.drawImage(logoImage, 0, 0, logoImage.naturalWidth, logoImage.naturalHeight);
      
            // ロゴをBase64エンコード
            const logoBase64 = logoCanvas.toDataURL();
      
            // SVGデータを生成
            const svgData = `
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                  viewBox="0 0 ${backgroundImage.naturalWidth} ${backgroundImage.naturalHeight}">
                <image xlink:href="${canvas.toDataURL()}" x="0" y="0" 
                      width="${backgroundImage.naturalWidth}" height="${backgroundImage.naturalHeight}" />
                <image xlink:href="${logoBase64}" x="${logoX}" y="${logoY}" 
                      width="${logoWidth}" height="${logoHeight}" 
                      preserveAspectRatio="xMidYMid meet" />
              </svg>
            `;
      
            const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
            const svgFile = new File([svgBlob], `logo_and_background_${Date.now()}.svg`, { type: 'image/svg+xml' });
      
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(svgFile);
            document.getElementById('js-logoLayoutNewFormFileInput').files = dataTransfer.files;
      
            document.getElementById('js-logoLayoutNewForm').submit();
            resolve();
          };
      
          logoImage.onerror = (error) => {
            console.error('Error loading logo image:', error);
          };
      
          logoImage.src = logoImage.src; // クロスオリジン設定を適用するために再読み込み
        }).catch((error) => {
          console.error('Error combining images:', error);
        });
      });
    };

    const logoOriginalInput = document.getElementById("order_template_logo_original");

    const uploadOriginalLogo = () => {
      return new Promise((resolve, reject) => {
        const file = fileInput.files[0];
        if (file) {
          const formData = new FormData();
          formData.append('file', file);

          const requestURI = '/templates/orders/upload-logo';
          fetch(requestURI, {
            method: 'POST',
            headers: {
              'X-CSRF-Token': token
            },
            body: formData
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            logoOriginalInput.value = data.file_name;
            resolve(data);
          })
          .catch(error => {
            console.error('Upload error:', error);
            alert('アップロードに失敗しました。通信状態をお確かめの上、複数回続く場合はお問い合わせください。');
            reject(error);
          });
        } else {
          resolve(null); // ファイルがない場合は即座に解決
        }
      });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      const files = fileInput.files;
      if (files.length > 0) {
        try {
          await uploadOriginalLogo();
          downloadSVG();
        } catch (error) {
          console.error('Error during form submission:', error);
          // エラー処理をここに追加
        }
      }
      document.getElementById('js-logoLayoutNewForm').submit();
    };

    document.getElementById('js-logoLayoutNewForm').addEventListener('submit', handleSubmit);
  }
});


/**
 * ユーザー管理画面
 * - データアップロード画面
 */
document.addEventListener("DOMContentLoaded", () => {
  const boxDataUploads = document.getElementById("js-boxDataUploads");
  if (!boxDataUploads) return;
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

  const dataUploads = document.querySelectorAll(".js-dataUpload");
  dataUploads.forEach((dataUpload) => {
    const wrapper = dataUpload.closest(".upload-wrapper");
    const orderId = wrapper.dataset.orderId;
    dataUpload.addEventListener("change", (event) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id', orderId);

      const requestURI = dataUpload.classList.contains('is-original') ? '/users/box_data_uploads/upload-original' : '/users/box_data_uploads/upload-logo';
      fetch(requestURI, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': token
        },
        body: formData
      })
      .then(data => {
        location.reload();
      })
      .catch(error => {
        console.error('Upload error:', error);
        alert('アップロードに失敗しました。通信状態をお確かめの上、複数回続く場合はお問い合わせください。');
      });
    });
  })
});

document.addEventListener('DOMContentLoaded', function() {
  const signUpForm = document.getElementById('js-signUpForm');
  if (!signUpForm) return;
  const addressField = document.querySelector('input[name="user[address]"]');
  const prefectures = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

  signUpForm.addEventListener('submit', function(event) {
    const value = addressField.value.trim();
    const hasPrefecture = prefectures.some(prefecture => value.startsWith(prefecture));

    if (!hasPrefecture) {
      // イベントをキャンセル
      event.preventDefault();
      alert('恐れ入りますが、住所は正式な都道府県名から入力してください。');
      addressField.focus();
    }
  });
});


window.addEventListener("load", () => {
  if(document.querySelector('.js-validateForm')) {
    const signUpForm = document.getElementById('js-signUpForm');
    if (signUpForm) {
      const termsConfirmed = signUpForm.querySelector('.js-termsConfirmation').checked;
      if (!termsConfirmed) {
        document.getElementById('js-formSubmit').classList.add('is-disabled');
      }
      signUpForm.querySelector('.js-termsConfirmation').addEventListener('change', () => {
        const termsConfirmed = signUpForm.querySelector('.js-termsConfirmation').checked;
        if (termsConfirmed) {
          document.getElementById('js-formSubmit').classList.remove('is-disabled');
        } else {
          document.getElementById('js-formSubmit').classList.add('is-disabled');
        }
      })
    }

    const validateRadioButtonAreas = document.querySelectorAll('.js-validateRadio');
    const inputValidateTargets = document.querySelectorAll('.js-validateInput');
    const spamElements = document.getElementsByClassName('js-spam');
    let firstErrorElement;

    // バリデーション関数
    const VALIDATION_PATTERNS = {
      phone: /^0\d{1,4}-\d{1,4}-\d{4}$/,
      fax: /^0\d{1,4}-\d{1,4}-\d{4}$/,
      email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
      postal: /^\d{3}-\d{4}$/,
      kana: /^[\u30A0-\u30FF\u31F0-\u31FF\u3099-\u309C\u30FC]*$/
    };

    // エラーメッセージの定義
    const ERROR_MESSAGES = {
      phone: '電話番号の形式が正しくありません（例: 123-4567-8910）',
      fax: 'FAX番号の形式が正しくありません（例: 123-4567-8910）',
      email: 'メールアドレスの形式が正しくありません',
      password: 'パスワードは半角英数字6文字以上で入力してください',
      passwordCheck: 'パスワードが一致しません',
      postal: '郵便番号の形式が正しくありません（例: 123-4567）',
      kana: 'カタカナで入力してください',
      empty: '必須項目です'
    };

    // バリデーション関数
    const validateInput = (value, type) => VALIDATION_PATTERNS[type].test(value);

    // エラー処理関数
    const handleError = (element, hasError, errorType) => {
      const parent = element.closest('td');
      parent.classList.toggle('is-error', hasError);

      const errorElement = parent.querySelector('.p-validateError');
      if (errorElement) {
        if (hasError) {
          if (!errorElement.dataset.originalText) {
            errorElement.dataset.originalText = errorElement.textContent;
          }
          errorElement.textContent = ERROR_MESSAGES[errorType];
          if (!firstErrorElement) {
            firstErrorElement = parent;
          }
        } else {
          errorElement.textContent = errorElement.dataset.originalText || '';
        }
      }
    };

    // inputからフォーカスが外れた時のバリデーション処理
    // システム全体に工数が嵩むため一旦コメントアウト
    // if (inputValidateTargets) {
    //   inputValidateTargets.forEach(validateTarget => {
    //     validateTarget.addEventListener('blur', function() {
    //       const value = this.value.trim();
    //       let isValid = value !== '';
    //       let errorType = 'empty';

    //       if (isValid) {
    //         if (this.classList.contains('js-validateTel')) {
    //           isValid = validateInput(value, 'phone');
    //           errorType = 'phone';
    //         } else if (this.classList.contains('js-validateEmail')) {
    //           isValid = validateInput(value, 'email');
    //           errorType = 'email';
    //         } else if (this.classList.contains('js-validatePass')) {
    //           isValid = validateInput(value, 'password');
    //           errorType = 'password';
    //         } else if (this.classList.contains('js-validatePostal')) {
    //           isValid = validateInput(value, 'postal');
    //           errorType = 'postal';
    //         }
    //       }

    //       handleError(this, !isValid, errorType);
    //     });
    //   });
    // }

    //カーソルを外した時の処理
    if(spamElements) {
      for (let i = 0; i < spamElements.length; ++i) {
        let spamElement = spamElements[i];
        spamElement.addEventListener('blur', function() {
          let mainText = spamElement.closest('.c-tableData').querySelector('.c-tableData__main').textContent;

          if (spamElement.value === mainText) {
            let parentElement = spamElement.closest('.c-tableData__box');
            parentElement.classList.remove('is-error');
          } else {
            let parentElement = spamElement.closest('.c-tableData__box');
            parentElement.classList.add('is-error');
          }
        })
      }
    }
    const dateField = document.querySelector('.js-birth-date');
    const dateError = document.querySelector('.js-date-error');

    if (dateField) {
      dateField.addEventListener('input', function(e) {
        const input = e.target.value;

        // 数字以外の文字を削除
        const cleanedInput = input.replace(/\D/g, '');

        // 入力を8桁に制限
        if (cleanedInput.length > 8) {
          e.target.value = cleanedInput.slice(0, 8);
          return;
        }

        // 入力が8桁未満の場合は、そのまま表示
        if (cleanedInput.length < 8) {
          e.target.value = cleanedInput;
          dateError.style.display = 'none';
          dateField.setCustomValidity('');
          return;
        }

        // 8桁の場合、日付の妥当性をチェック
        const dateString = cleanedInput.slice(0, 4) + "-" + cleanedInput.slice(4, 6) + "-" + cleanedInput.slice(6, 8);
        const day = new Date(dateString);

        if (Number.isNaN(day.getTime()) || day.getFullYear() != cleanedInput.slice(0, 4)) {
          dateError.style.display = 'block';
          dateField.setCustomValidity('Invalid date');
        } else {
          dateError.style.display = 'none';
          dateField.setCustomValidity('');
          // 日付を YYYY-MM-DD 形式に変換
          e.target.value = dateString;
        }
      });
      const dateInput = dateField.value;
      if ((dateInput.length && dateInput.length !== 10) || (dateInput.length && Number.isNaN(new Date(dateInput).getTime()))) {
        event.preventDefault();
        dateError.style.display = 'block';
      }
    }

  document.getElementById('js-formSubmit').addEventListener('click', function(event) {
    event.preventDefault();

    if (signUpForm) {
      const termsConfirmed = signUpForm.querySelector('.js-termsConfirmation').checked;
      if (termsConfirmed) {
        document.getElementById('js-formSubmit').classList.remove('is-disabled');
      }
    }

    let form = document.querySelector('.js-validateForm');
    let inputs = form.querySelectorAll('.js-validateEmpty'); // 必須項目のinputとselect要素を取得
    firstErrorElement = null;
    // 必須項目のチェック
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i];
      let parent = input.closest('td'); // 親要素の.c-tableDataを取得

      if (input.type === 'radio') {
        // ラジオボタンの場合、同じname属性の要素で1つ以上が選択されているかチェック
        let radioGroup = form.querySelectorAll('input[type="radio"][name="' + input.name + '"]');
        let isChecked = false;

        for (let j = 0; j < radioGroup.length; j++) {
          if (radioGroup[j].checked) {
            isChecked = true;
            break;
          }
        }

        if (!isChecked) {
          // ラジオボタンが選択されていない場合
          parent.classList.add('is-error');

          if (!firstErrorElement) {
            firstErrorElement = parent;
          }
        } else {
          // ラジオボタンが正しく選択されている場合、エラークラスを削除
          for (let k = 0; k < radioGroup.length; k++) {
            let radioParent = radioGroup[k].closest('.c-tableData');
            radioParent.classList.remove('is-error');
          }
        }
      } else {
        // テキスト入力やセレクトボックスなどの場合
        let inputIsValid = input.value !== '';
        let inputErrorType = 'empty';

        if (inputIsValid) {
          if (input.classList.contains('js-validateTel')) {
            inputIsValid = validateInput(input.value, 'phone');
            inputErrorType = 'phone';
          } else if (input.classList.contains('js-validateEmail')) {
            inputIsValid = validateInput(input.value, 'email');
            inputErrorType = 'email';
          } else if (input.classList.contains('js-validatePass')) {
            inputIsValid = validateInput(input.value, 'password');
            inputErrorType = 'password';
          } else if (input.classList.contains('js-validatePostal')) {
            inputIsValid = validateInput(input.value, 'postal');
            inputErrorType = 'postal';
          } else if (input.classList.contains('js-validateFax')) {
            inputIsValid = validateInput(input.value, 'fax');
            inputErrorType = 'fax';
          } else if (input.classList.contains('js-validateKana')) {
            inputIsValid = validateInput(input.value, 'kana');
            inputErrorType = 'kana';
          }
        }

        handleError(input, !inputIsValid, inputErrorType);

        // if (!input.value) {
        //   // 必須項目が未入力または未選択の場合

        //   if(parent02) {
        //     parent02.classList.add('is-error');
        //   } else {
        //     parent.classList.add('is-error');
        //   }

        //   if (!firstErrorElement) {
        //     firstErrorElement = parent;
        //   }
        // } else {
        //   // 必須項目が正しく入力されている場合、エラークラスを削除
        //   parent.classList.remove('is-error');
        // }
      }
    }

    // 都道府県選択欄のエラーチェック
    let selectElement = document.getElementById('js-prefectures');
    if (selectElement) {
      let parentElement = selectElement.closest('.c-tableData__box');

      if (selectElement.value !== '') {
        parentElement.classList.remove('is-error');
      } else {
        parentElement.classList.add('is-error');
      }

      selectElement.addEventListener('change', function() {
        if (this.value !== '') {
          parentElement.classList.remove('is-error');
        } else {
          parentElement.classList.add('is-error');
        }
      });
    }

    if(validateRadioButtonAreas) {
      for (let i = 0; i < validateRadioButtonAreas.length; ++i) {
        let validateRadioButtonArea = validateRadioButtonAreas[i];
        validateRadioButtonArea.addEventListener('change', function() {
          validateRadioButtonArea.classList.remove('is-error');
        });
      }
    }

    
      let mainPassElement = document.querySelector('.js-mainPass');
      let passElement = document.querySelector('.js-pass');
      let target = document.querySelector(".target");
      console.log({mainPassElement, passElement, target});
      if (mainPassElement && passElement) {
        let mainPass = mainPassElement.value;
        let pass = passElement.value;
        console.log({mainPass, pass});

        if (mainPass !== pass) {
          // 一致していない場合の処理
          target.classList.add('is-error');
          const errorElement = target.querySelector('.p-validateError');
          errorElement.textContent = ERROR_MESSAGES.passwordCheck;
          // firstErrorElementを更新
          if (!firstErrorElement) {
            firstErrorElement = target;
          }
        }
      }

      //カーソルを外した時の処理
      passElement?.addEventListener("blur", function () {
        let mainPass = mainPassElement.value;
        let pass = passElement.value;
        if (mainPass === pass) {
          target.classList.remove("is-error");
        } else {
          target.classList.add("is-error");
        }
      });

    if (dateField) {
      const dateInput = dateField.value;
      if ((dateInput.length && dateInput.length !== 10) || (dateInput.length && Number.isNaN(new Date(dateInput).getTime()))) {
        dateError.style.display = 'block';
        if (!firstErrorElement) {
          firstErrorElement = dateError;
        }
      }
    }


    for (let i = 0; i < spamElements.length; i++) {
      let spamElement = spamElements[i];
      let mainText = spamElement.closest('.c-tableData').querySelector('.c-tableData__main').textContent;

      if (spamElement.value === mainText) {
        // 一致している場合の処理
        let parentElement = spamElement.closest('.c-tableData__box');
        parentElement.classList.remove('is-error');
      } else {
        // 一致していない場合の処理
        let parentElement = spamElement.closest('.c-tableData__box');
        parentElement.classList.add('is-error');
        if (!firstErrorElement) {
          firstErrorElement = parentElement;
        }
      }
    }
    // エラーメッセージの表示
    if (firstErrorElement) {
      alert('必須項目の空欄または入力エラーがあります');
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      // エラーがない場合はフォームを送信
      form.submit();
    }
  });
  }
});

// 修正するボタンでページ戻り
document.addEventListener('DOMContentLoaded', () => {
  const goBackButtons = document.querySelectorAll('.js-go-back');
  
  goBackButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      const steps = parseInt(event.currentTarget.dataset.steps, 10);
      if (!isNaN(steps) && steps >= 2 && steps <= 5) {
        history.go(-steps);
      } else {
        console.error('Invalid number of steps');
      }
    });
  });
});
